import styled from "styled-components"

const Divider = styled.hr`
  border: 1px solid ${({ color }) => color || "white"};
`

const PostCardElement = styled.div`
  margin: ${({ theme: { spacing } }) => spacing.s50} 0;

  @media only screen and (min-width: 768px) {
    margin: 100px 0;
  }
`

const PostCardImageWrapper = styled.div`
  margin-bottom: ${({ theme: { spacing } }) => spacing.s50};
`

const PostCardImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`

const PostCardCategory = styled.div`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s30};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w400};
  margin-bottom: ${({ theme: { spacing } }) => spacing.s20};
`

const PostCardTitle = styled.div`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s30};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w500};
  line-height: ${({ theme: { spacing } }) => spacing.s35};
  margin-top: ${({ theme: { spacing } }) => spacing.s30};
`

const PostCardDescription = styled.div`
  margin-top: ${({ theme: { spacing } }) => spacing.s30};
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s21};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w400};
  line-height: ${({ theme: { spacing } }) => spacing.s35};
`

export {
  PostCardDescription,
  PostCardTitle,
  PostCardCategory,
  PostCardImage,
  PostCardImageWrapper,
  PostCardElement,
  Divider,
}
