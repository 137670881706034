import React from "react"
import {
  PostCardDescription,
  PostCardTitle,
  PostCardCategory,
  PostCardImage,
  PostCardImageWrapper,
  PostCardElement,
  Divider,
} from "./style"

const PostCard = ({ src, title, description, category, color }) => {
  return (
    <PostCardElement>
      <PostCardImageWrapper>
        <PostCardImage src={src} />
      </PostCardImageWrapper>
      <PostCardCategory
        dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(category)}` }}
      ></PostCardCategory>
      <Divider color={color} />
      <PostCardTitle>{title}</PostCardTitle>
      <PostCardDescription
        dangerouslySetInnerHTML={{ __html: description }}
      ></PostCardDescription>
    </PostCardElement>
  )
}

export default PostCard
